import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/f8b5ddf03621fe476f8e3e0155fad40d/2bef9/Morne_portrait_of_a_young_woman_with_white_hair_she_is_a_studen_1fd1b3de-e5d3-4d5d-8d41-f2cec83ce3e2.png",
            "srcSet": ["/static/f8b5ddf03621fe476f8e3e0155fad40d/5ff7e/Morne_portrait_of_a_young_woman_with_white_hair_she_is_a_studen_1fd1b3de-e5d3-4d5d-8d41-f2cec83ce3e2.png 375w", "/static/f8b5ddf03621fe476f8e3e0155fad40d/1d69c/Morne_portrait_of_a_young_woman_with_white_hair_she_is_a_studen_1fd1b3de-e5d3-4d5d-8d41-f2cec83ce3e2.png 750w", "/static/f8b5ddf03621fe476f8e3e0155fad40d/2bef9/Morne_portrait_of_a_young_woman_with_white_hair_she_is_a_studen_1fd1b3de-e5d3-4d5d-8d41-f2cec83ce3e2.png 1024w"],
            "width": "350px",
            "className": "right center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Eileen Quint`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Eileen Quint is the daughter of `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{` and and a student at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` studying to be a `}<a parentName="p" {...{
        "href": "/Acronist#boneforger",
        "title": "Acronist"
      }}>{`Acronist`}</a>{`. At the Basentia, her primary fields of study have been archeology and cartography. She h`}</p>
    <h2>{`Events of Enzo Quint's Dissapearance`}</h2>
    <p>{`Eileen was an instrumental person in the events that followed her father Enzo's disappearance. She found a `}<a parentName="p" {...{
        "href": "/The%20Sixth%20Tinwheel",
        "title": "The Sixth Tinwheel"
      }}>{`The Sixth Tinwheel`}</a>{`, the proceeded to hire and work alongside `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin Fane`}</a>{`, `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar Stillwater`}</a>{`, `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian Cullpepper`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo Livingston`}</a>{` to solve the case.`}</p>
    <p>{`During the investigation, she, along with `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian Cullpepper`}</a>{`, went to the `}<a parentName="p" {...{
        "href": "/Central%20Bank%20of%20Meripol",
        "title": "Central Bank of Meripol"
      }}>{`Central Bank of Meripol`}</a>{` in order to investigate Enzo's accounts, looking for potential clues. While there, the bank was held up by a hired thug named `}<a parentName="p" {...{
        "href": "/Roz%20Daras",
        "title": "Roz Daras"
      }}>{`Roz Daras`}</a>{`, who seemed to also want access to Enzo's accounts. They thwarted the would-be bank robber, and Eileen gained access to Enzo's deposit box, in which she discovered `}<a parentName="p" {...{
        "href": "/The%20First%20Tinwheel",
        "title": "The First Tinwheel"
      }}>{`The First Tinwheel`}</a>{` and papers describing various payments that Enzo was making and receiving. Payments for purchases to a corporation called `}<a parentName="p" {...{
        "href": "/Crimson",
        "title": "Crimson"
      }}>{`Crimson`}</a>{`, now identified to be a shell for the `}<a parentName="p" {...{
        "href": "/Sonxai",
        "title": "Sonxai"
      }}>{`Sonxai`}</a>{`, and the `}<a parentName="p" {...{
        "href": "/Prisma%20Travel%20Corporation",
        "title": "Prisma Travel Corporation"
      }}>{`Prisma Travel Corporation`}</a>{`, for transport of unknown goods from the town of `}<a parentName="p" {...{
        "href": "/Gris",
        "title": "Gris"
      }}>{`Gris`}</a>{`, as well as payments `}<em parentName="p">{`from`}</em>{` `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{`.`}</p>
    <p>{`She has kept tabs on her brother, `}<a parentName="p" {...{
        "href": "/Vytas%20Quint",
        "title": "Vytas Quint"
      }}>{`Vytas Quint`}</a>{`, and father, `}<a parentName="p" {...{
        "href": "/Ari%20Quint",
        "title": "Ari Quint"
      }}>{`Ari Quint`}</a>{`, giving them updates on her investigation. She also took care of her brother, who was brought to their house by the `}<a parentName="p" {...{
        "href": "/Protoxy",
        "title": "Protoxy"
      }}>{`Protoxy`}</a>{` `}<a parentName="p" {...{
        "href": "/Emere%20Renguard",
        "title": "Emere Renguard"
      }}>{`Emere Renguard`}</a>{`, clearly high on `}<a parentName="p" {...{
        "href": "/Lotus",
        "title": "Lotus"
      }}>{`Lotus`}</a>{`. Her brother is the one who gave her the idea to check Enzo's accounts.`}</p>
    <p>{`She infiltrated a gala hosted by the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{`, together with `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo Livingston`}</a>{`, `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian Cullpepper`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar Stillwater`}</a>{` in order to investigate their involvement in her Father's dissapearance. There, she met and talked with various member's of the `}<a parentName="p" {...{
        "href": "/Aljiedum%20Noble%20Houses",
        "title": "Aljiedum Noble Houses"
      }}>{`Noble Houses`}</a>{`, including meeting `}<a parentName="p" {...{
        "href": "/Vera%20Lauten",
        "title": "Vera Lauten"
      }}>{`Vera Lauten`}</a>{` herself.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      